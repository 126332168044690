#vrtx-course-schedule {

  #right-main,
  #total-main {
    position: relative;
  }

  .vrtx-subscribe-component {
    position: absolute;
    top: -52px;
    right: 0;
    display: inline-block;

    .vrtx-subscribe-wrapper {
      top: 31px;
      min-width: 370px;
      max-width: 100%;
    }

    .style-form {
      padding: 8px 14px 7px 14px;

      label {
        display: block;
      }
    }
  }

  a.vrtx-ical-help {
    background: url(.././images/calendar-info-icon.png) no-repeat 0 0;
    width: 14px;
    height: 14px;
    display: inline-block;
    margin: 0 0 0 5px;
    position: relative;
    bottom: -.4em;
    float: right;
  }

  .cs-wrp,
  .accordion-content {
    position: relative;
  }

  /* Responsive text */
  a.long,
  .r {
    display: none;
  }

  td .alert {
    display: block;
  }

  tr {
    &.odd td {
      background-color: #eee;
    }
    &:focus,
    &:hover {
      td {
        background-color: #ebf3f9;
        border-color: #c6dadf;
      }
    }
  }

  td {
    &.title,
    &.staff,
    &.place,
    &.res {
      word-wrap: break-word;
      word-break: normal;
      overflow-wrap: break-word;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
    }

    &.time,
    &.date {
      white-space: nowrap;
    }

    &.edit-cell {
      padding: 0;
    }

    > .r + *,
    > .r + * > li:first-child,
    > div > .r + *,
    > div > .r + * > li:first-child {
      margin-top: 0;
    }

    > *:last-child,
    > div > *:nth-last-child(2) {
      margin-bottom: 0;
    }
  }

  th {
    &.date  { width: 11%; }
    &.time  { width: 12%; }
    &.title { width: 47%; }
    &.place { width: 30%; }
  }

  table {
    font-size: 1.6rem;
    table-layout: fixed;

    &.has-staff th {
      &.title { width: 35%; }
      &.place { width: 21%; }
      &.staff { width: 21%; }
    }
    &.has-res th {
      &.title { width: 26%; }
      &.place { width: 21%; }
      &.res   { width: 30%; }
    }
    &.has-staff.has-res th {
      &.title { width: 21%; }
      &.place { width: 15%; }
      &.staff { width: 15%; }
      &.res   { width: 26%; }
    }
  }

  .status {
    color: red;
    font-weight: bold;
    padding-right: .4em;
  }

  .edit-wrp {
    padding: 10px;
    display: block;
    position: relative;
  }

  .button.edit-link {
    background: @btnPrimaryBg;
    color: @btnPrimaryColor;
    border: none;

    &:focus,
    &:hover {
      background: @btnPrimaryBgHover;
      color: @btnPrimaryColorHover;
    }

    &:after {
      display: none;
    }
  }

  .edit-link,
  .edit-locked,
  .edit-locked-wrp {
    display: none;
  }

  .edit-locked-wrp-inner,
  .edit-locked-wrp-inner * {
    display: block;
    clear: left;
  }

  .edit-locked-title {
    background: transparent url("/vrtx/__vrtx/static-resources/themes/default/images/lock.png") no-repeat scroll 100% 7px;
    display: inline-block;
    font-weight: bold;
    padding: 3px 18px 3px 0;

    + a {
      margin-top: -5px;
    }
  }

  .can-edit tr {
    &.visible,
    &:hover {
      a.edit-link,
      .edit-locked {
        display: block;
        position: absolute;
        top: 5px;
        right: 5px;
        margin: 0;
        z-index: 1;
        width: auto;
      }
      .edit-locked-wrp {
        display: block;
        position: absolute;
        bottom: -4.2em;
        right: -1px;
        padding: 2px 10px 5px 10px;
        z-index: 1;
        background: #fff;
        border: 2px solid #000;
        min-width: 220px;
      }
    }
  }

  .cs-toc-content {
    display: block;
  }
  .cs-toc-section-link.loading {
    padding-right: 25px;
    background: url("/vrtx/__vrtx/static-resources/themes/default/images/spinner.gif") no-repeat center right;
  }
  .cs-toc-section-link {
    text-decoration: none;

    &:hover,
    &:focus {
      .textUnderlineHover(@textColor);
    }
  }

  .res-more-toggle {
    display: block;
    clear: left;
  }

  .res-more {
    display: none;

    &.visible {
      display: block;
      position: absolute;
      background: #fff;
      z-index: 9999;
      margin-top: 5px;
      min-width: 220px;
      padding: 6px 11px 4px 11px;
      border: 1px solid #000;

      > *:first-child              { margin-top: 0;      }
      > *:last-child               { margin-bottom: 5px; }
      > *:last-child li:last-child { margin-bottom: 0px; }
    }
  }

  /* Expand all */

  #cs-expand-all {
    position: absolute;
    top: 0;
    right: 0;
  }

  #activities {
    position: relative;
  }

  #activities.expanded-all {
    .res-more-toggle,
    .ui-accordion-header-icon {
      display: none;
    }

    .accordion-content.ui-accordion-content-show {
      display: block !important;
    }

    .res-more {
      display: block;
      position: static;
      padding: 0;
      margin: 0;
      min-width: 0;
    }
    .cs-toc-content > .ui-accordion h3.accordion {
      border-bottom: none;
      border-top: none;

      a {
        color: #444;
      }
    }
    .cs-toc-content > .ui-accordion .accordion-content,
    .ui-accordion-header {
      padding-left: 0;
    }
  }
}

.edit-locked-wrp-inner,
.vrtx-minestudier-program-courses-wrapper-inner {
  &:before,
  &:after {
    border: medium solid transparent;
    content: " ";
    height: 0;
    position: absolute;
    bottom: 100%;
    left: 20px;
    width: 0;
  }
  &:before {
    border-color: rgba(188, 180, 180, 0) rgba(188, 180, 180, 0) #bcb4b4;
    border-width: 11px;
    margin-left: -11px;
  }
  &:after {
    border-color: rgba(255, 255, 255, 0) rgba(255, 255, 255, 0) #fff;
    border-width: 10px;
    margin-left: -10px;
  }
}

// Upload course videos

#main #right-main .course-upload-btn,
#main #total-main .course-upload-btn {
  position: absolute;
  top: 46px;
  right: 0px;
  margin: 0;
  padding-right: 20px;
  background-image: none;
}

#vrtx-audio-video-listing #right-main,
#vrtx-audio-video-listing #right-main {
  position: relative;
}

@media @responsiveBreakpoint {

   body#vrtx-course-schedule #main {
     #activities {
       margin-top: -20px;
     }

     #cs-expand-all {
       display: none;
     }

     .course-schedule-toc-content .display-as-h3,
     .cs-toc-content .display-as-h3,
     .course-schedule-toc-thirds,
     .cs-toc-thirds {
       float: none;
     }

     .cs-toc-content {
       margin: 0 -15px;
     }

     .vrtx-subscribe-wrapper {
       right: auto;
       left: 0px;

       .vrtx-dropdown-title a {
         color: #fff;
       }
     }

     .vrtx-subscribe-component {
       position: relative;
       top: -10px;

       .vrtx-dropdown-link {
         padding-right: 0;
       }
     }

     .cs-toc-content > .ui-accordion .accordion-content {
       padding: 20px 15px 0 15px;
       margin: 0;

       .accordion-content {
         position: relative;
         margin-left: 0;
         margin-right: 0;
         padding-top: 0;
         padding-left: 15px;
         padding-right: 15px;
       }
     }

     table .accordion-wrapper .ui-accordion-content {
       margin-top: 0;
     }

     .vrtx-ical-help,
     table td.place .short,
     table caption,
     table thead {
       display: none;
     }

     a.vrtx-ical-help {
       right: 15px;
     }

     .cs-wrp.accordionized h4,
     .cs-toc-title,
     .cs-toc-content {
       display: block;
     }

     .cs-wrp {
       padding-top: 45px;
     }

     .accordion-content .cs-wrp {
       margin-top: -45px;
     }

     h3 {
       margin-left: 15px;
       margin-right: 15px;
     }

     // Expanded only one
     .cs-toc-content > .cs-wrp {
       > .vrtx-subscribe-component {
         left: 15px;
       }
       > table .accordion-wrapper {
         h4.ui-accordion-header,
         &.ui-accordion.ui-widget .ui-accordion-content {
           padding-left: 15px;
           padding-right: 30px;
         }
         h4.ui-accordion-header .ui-icon {
           right: 15px;
         }
       }
     }

     table,
     table tbody,
     table tr,
     table td,
     table td.place .long,
     table td.edit-cell {
       display: block;
       border: none;
       width: auto;
     }

     .cs-toc-content ul {

     }
     .accordion-wrapper,
     table {
       margin: 0;
     }

     table .edit-wrp,
     table td {
       padding: 0;
     }

     tr:focus td,
     tr:hover td {
       border-color: #d0d0d0;
       background-color: #fff;
     }

     table .edit-locked,
     table .edit-locked-wrp,
     table .edit-link {
       clear: both;
       width: 100%;
       margin: 10px 0;
       display: block;
       position: static;
     }

     table .edit-locked,
     table .edit-locked-wrp {
       padding: 0;
       min-width: 0;
       border: none;

       .edit-locked-wrp-inner {
         &:before,
         &:after {
           display: none;
         }
       }
       .edit-locked-title {
         margin-right: 100%;
         white-space: nowrap;
         background-position: 100% 10px;
       }
     }

     table .accordion-content td {
       padding-bottom: 1ex;
     }

     table .accordion-content td:last-child {
       padding-bottom: 1.5ex;
     }

     table td,
     tr.odd td {
       background: none;
     }

     tr + .r {
       margin-top: 20px;
       border-top: 1px solid #000;
       padding-top: 20px;
     }

     table td .r {
       display: inline-block;
       min-width: 100px;
       font-weight: bold;
       margin-bottom: 10px;
     }

     table td.place,
     table td.staff,
     table td.res {
       margin-top: 15px;
     }

     table td.title .r,
     table td.place .r,
     table td.staff .r,
     table td.res .r,
     .res-more,
     .res-after {
       display: block;
     }

     h4 td .r,
     .res-more-toggle {
       display: none;
     }

     .accordion-wrapper h4.ui-accordion-header {
       background: none;
       border-top: 1px solid #ccc;
       margin-top: 0;
       padding: 13px 15px 13px 15px;
       font-weight: normal;
       color: @textColor;

       .ui-icon {
         right: 15px;
       }
     }

     // .ui-corner-all = collapsed
     // .ui-corner-top = expanded

     table h4.ui-accordion-header {
       td {
         display: inline-block;
         white-space: nowrap;

         span:not(.r) {
           display: inline;
         }
       }

       &.ui-corner-top .r {
         display: inline-block;
       }

       &.ui-corner-all:nth-child(4n-1) {
         background-color: #eee;
       }

       &:nth-last-child(2) {
         border-bottom: 1px solid #ccc;
       }

       &.ui-corner-top:nth-last-child(2) {
         border-bottom: none;
       }

       &.ui-corner-top .r-time {
         display: none;
       }
     }
     table tr td.date {
       display: none;
     }
   }

  #main #right-main .course-upload-btn,
  #main #total-main .course-upload-btn {
    top: 0;
    position: relative;
    margin: -10px 0 40px;
    display: inline-block;

    #vrtx-audio-video-listing h1,
    #vrtx-course-schedule h1 {
      display: block;
    }
  }
}

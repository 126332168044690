#vrtx-listing-filters {
  margin-bottom: 60px;

  ul {
    margin: 0;
  }

  &:after {
    .clearfix();
  }
}

#main {
  .vrtx-listing-filter-parameter,
  #vrtx-program-filters li {
    font-size: 1.6rem;
    letter-spacing: 0.23px;
    margin-bottom: 15px;
  }
  /* not in use
  .vrtx-listing-filter-parameter-all + .vrtx-listing-filter-parameter,
  .only-fagomraader li:first-child,
  .only-typer li:first-child {
  }
  */
  #vrtx-program-filters li,
  .vrtx-listing-filter-parameter {
    &:before {
      display: none;
    }
    line-height: 3rem;
    margin-left: 0;

    a {
      .checkbox-style();

      &[aria-checked='true'] {
        .checkbox-style-checked();
      }
    }
    &[aria-checked='true'],
    &.selected,
    &.vrtx-listing-filter-parameter-selected {
      a {
        .checkbox-style-checked();
      }
    }
  }
}

#main {
  .listing-filters-hidden {
    display: none;
  }
  .vrtx-listing-filters-expand {
    display: block;
    margin-bottom: 30px;
  }
}

.vrtx-paging-feed-wrapper + #vrtx-listing-filter-status {
  margin-top: 10px;
}

#vrtx-listing-filter-no-results {
  margin: 10px 0 15px 0;
}


#vrtx-course-description-listing,
#vrtx-student-exchange-agreement-listing {
  .vrtx-subfolder-menu {
    +.vrtx-listing-filters-expand,
    +#vrtx-listing-filters {
      &,
      +#vrtx-listing-filters {
        margin-top: 0px;
        padding-top: 15px;
      }
    }
    ul li {
      margin-left: 0;
      &:before {
        content: ' ';
      }
      a {
        padding-left: 34px;
        text-decoration-color: transparent;
        display: inline-block;
        &:before {
          content: "";
          background: url(/vrtx/dist/resources/uio2/css/images/arrows/arrow-big.svg) no-repeat 0 0;
          background-size: 100% auto;
          height: 1em;
          width: 1em;
          display: inline-block;
          position: absolute;
          left: 0;
          top: .8ex;
          transition: left .3s;
        }
        &:hover {
          text-decoration-color: currentColor;
          &:before {
            left: 0.8ex;
          }
        }
      }
    }
  }
  div#vrtx-listing-filter-results, #vrtx-listing-filter-hits {
    max-width: 1000px;
  }
}

@media @responsiveBreakpoint {

  #vrtx-listing-filters,
  #vrtx-program-filters {
     display: none !important;

     &.responsive-hidden-before-ready {
       display: block !important;
     }
   }

   .vrtx-listing-filters-expand {
     display: none !important;
   }

   .listing-filters-hidden {
     display: block !important;
   }

   #main {
     #vrtx-program-filters,
     #vrtx-program-filters-topic,
     #vrtx-program-filters-type,
     #vrtx-program-filters-topic div.only-fagomraader,
     #vrtx-program-filters-type div.only-typer,
     #vrtx-program-filters h2,
     ul.vrtx-programs,
     ul.vrtx-programs li,
     ul.vrtx-programs h2,
     ul.vrtx-programs .introduction,
     #vrtx-program-links,
     #vrtx-listing-filters h2,
     #vrtx-listing-filters .vrtx-listing-filters-section {
       float: none;
       width: auto;
     }
   }

   #vrtx-program-filters-type {
     padding-left: 0;
   }

   #vrtx-listing-filter-results {
     li {
       margin-bottom: 30px;

       &:last-child {
         margin-bottom: 0;
       }
     }
   }

   .study-filters {
     padding: 0;
     border: none;
   }

   #vrtx-program-filters-topic div.only-fagomraader,
   #vrtx-program-filters-type div.only-typer {
     padding: 10px 0 0 0;
     margin: 10px 0 0 0;
   }

   .study-filters-wrapper {
     &,
     h2 {
       margin: 0;
       padding: 0;
     }

     h2 {
       font-size: 1.8rem;
       line-height: 2.7rem;
     }

     li a {
       color: #2b2b2b;
     }
   }

   .study-filters-toggle {
     width: 100%;
     padding-right: 65px;
     margin-top: 0;
     margin-bottom: 0;

     .state {
       display: none;
     }
   }

   #vrtx-listing-filters {
     background: none;
     margin-bottom: 10px;

     .vrtx-listing-filters-section {
       margin: 0 0 10px 0;
       padding-left: 0;
       padding-right: 0;

       &:last-child {
         margin-bottom: 0;
       }
     }
   }

   .vrtx-listing-filters-3-col .study-filters-wrapper:not(:last-child) {
     margin-bottom: -1px;
   }

   .study-filters-has-selected .state {
     background: url(./images/responsive/study-filters-selected-arrow.png) no-repeat center center;
     width: 1ex;
     height: 100% !important;
   }

   .vrtx-program-buttons,
   a#vrtx-program-filters-type-help {
     display: none;
   }

   .study-filters {
     > div.opened .state,
     > div.opened .study-filters-selected,
     .study-sticky,
     .scroller > :not(h2) {
       display: none;
     }

     > div.opened {
       .study-sticky,
       .scroller > :not(h2) {
         display: block;
       }

       > .study-sticky .study-sticky-buttons {
         padding: 65px 15px 20px 15px;
         background: transparent;
       }

       > div .modal-like-bottom-gradient {
         background: rgb(255,255,255);
         background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
         height: 50px;
         display: block;
         margin-top: -60px;
       }

       .study-select,
       .study-cancel {
         width: auto;
         padding: 12px 42px;
         font-size: 1.8rem;
         line-height: 2.7rem;

         &:after {
           display: none;
         }
         &:hover,
         &:focus {
           padding-left: 42px;
         }
       }

       .study-cancel {
         margin-left: 25px;
       }
     }
   }

   html.opened-modal-like {
     &,
     body {
       background: #fff;
       height: 100%;
       overflow: hidden;
     }
     #head-wrapper,
     #footer-wrapper,
     #bottomnav,
     #marketing-container,
     #head-admin-menu-wrapper,
     .toggle-more-opts:after,
     #sidebar-toggle-link {
       display: none;
     }

     #offcanvas-outer-wrapper,
     #offcanvas-wrapper,
     #inner-content-outer-wrapper,
     #inner-content-wrapper,
     #inner-inner-wrapper,
     #inner-wrapper,
     #outer-wrapper,
     #uiodoc-wrapper,
     #uiodoc,
     #main,
     #total-main,
     #right-main,
     .study-filters,
     .study-filters-wrapper {
       overflow: hidden !important;
       height: 100% !important;
       width: 100% !important;
       float: none !important;
       border-width: 0 !important;
       padding: 0 !important;
       margin: 0 !important;
       position: static !important;
     }

     #main .opened {
       h2 {
         font-size: 3rem;
         line-height: 3.7rem;
         margin: 0;
         padding: 20px 15px 30px 15px;
       }

       .study-filters-inner-wrapper {
         position: absolute;
         overflow: auto;
         bottom: 80px;
         left: 0px;
         top: 0px;
         width: 100% !important;
         z-index: 1;
       }

       .scroller {
         position: absolute;
         width: 100% !important;
         z-index: 1;

         > *:not(h2) {
           margin-left: 15px !important;
           margin-right: 15px !important;
         }

         > ul {
           margin-top: 10px !important;
         }

         > *:last-child {
           margin-bottom: 20px;
         }
       }

       .study-filters-toggle {
         background: transparent;
         color: @textColor;
         padding: 0;
         margin: 0;
         text-transform: none;
       }

       .study-sticky {
         padding: 0;
         height: 70px;
         z-index: 2;
         position: absolute;
         bottom: 0px;
         left: 0px;
         width: 100% !important;
       }
     }
   }

   #main #vrtx-program-filters,
   #main #vrtx-listing-filters {
     .study-filters-selected {
       margin: 10px 0 40px 10px;

       li {
         margin-left: 20px;
         font-size: 1.8rem;
         line-height: 2.7rem;

         &:before {
           display: block;
           font-size: .7em;
           top: -.1ex;
         }
       }
     }
   }

   #main #vrtx-program-filters-type .study-filters-selected {
     margin-bottom: 0;
   }
}

@imagePath: '/vrtx/dist/resources/uio2/css/images/';

@media print {

  /*
  html {
    font-size: 48%;
  }
  html.msie {
    font-size: 81%;
  }
  */

  /* Grid */
  html,
  body {
    background-color: transparent !important;
  }

  body {
    min-width: 0 !important;
  }

  // Grid resets
  #head-wrapper,
  .vrtx-social-components,
  iframe,
  #footer-wrapper,
  #footer-wrapper-back-to-uio,
  #head-admin-menu-wrapper,
  #privacy-policy-link-wrapper,
  #main #left-main,
  #breadcrumbs {
    display: none;
  }

  #footer-wrapper #footers,
  #head-wrapper #head,
  .grid-container.uio-info-message.alert .row,
  .info-link-wrapper .info-link,
  .grid-container.grid-container-top .row,
  .uio-app-line-top {
    max-width: none;
    min-width: 0;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  
  .vrtx-article-body {
    max-width: none !important;
  }
  .vrtx-introduction-image {
    max-width: 100% !important;
  }

  #main {
    max-width: none;
    min-width: 0;
    min-height: 0 !important;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;

    #total-main,
    #right-main {
      padding-left: 0;
      max-width: none;
      min-width: 0;
    }
    .grid-container {
      margin-bottom: 60px;
    }
    .row-all-colored {
      padding-top: 60px;
      padding-bottom: 60px;

      &:last-child {
        margin-bottom: 60px; // reset
      }
    }
  }

  #vrtx-frontpage.total-main #main {
    h1 {
      margin-left: 0;
    }

    .row,
    .vrtx-back,
    .vrtx-context-box,
    h1,
    #breadcrumbs,
    #vrtx-frontpage-introduction {
      max-width: none;
      min-width: 0;
      padding-right: 0;
      padding-left: 0;
      margin-left: 0;
      margin-right: 0;
    }

    .row {
      padding-left: 0;
    }

    .grid-container {
      width: auto;
      margin-left: 0;
    }
  }

  #vrtx-frontpage #main .sub-header-container .row {
    min-width: 0;
    padding-left: 0 !important;
  }

  body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit) {
    #vrtx-main-content,
    #vrtx-main-user {
      > *:not(h1):not(.vrtx-introduction):not(.small-intro-image):not(#vrtx-main-user):not(blockquote):not(.vrtx-commments) {
        max-width: none;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  // Some part of course description and semester shall be narrower and centered
  body#vrtx-course-description #vrtx-course-semesters,
  body#vrtx-course-description #vrtx-course-content,
  body#vrtx-course-description .vrtx-date-info,
  body#vrtx-semester #vrtx-main-content .vrtx-frontpage-box:not(:first-child),
  body#vrtx-student-exchange-agreement #vrtx-main-content > *:not(#vrtx-student-exchange-agreement-contact):not(#vrtx-student-exchange-agreement-university-info):not(.vrtx-introduction):not(#vrtx-student-exchange-agreement-info):not(h1) {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }

  body:not(#vrtx-frontpage):not(#vrtx-course-description):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-person):not(#vrtx-unit-person-listing):not(#vrtx-organizational-unit):not(#vrtx-unit) {
    .vrtx-frontpage-box {
      h1 {
        margin-left: 0;
      }
    }
  }
  body:not(#vrtx-frontpage):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-course-description):not(#vrtx-semester):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-organizational-unit):not(#vrtx-unit) {
    #vrtx-additional-content {
      .vrtx-frontpage-box {
        margin-left: 0;
      }
    }
  }

  #vrtx-event-listing.datepicker-only-right-column {
    #vrtx-calendar-listing,
    #vrtx-content {
      #vrtx-main-content {
        width: 100%;
      }
      #vrtx-additional-content {
        width: 100%;;
      }
    }
  }

  // New: figure and facts container
  body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit)
    #vrtx-main-content,
  body:not(#vrtx-frontpage):not(#vrtx-event-listing):not(#vrtx-article-listing):not(#vrtx-searchview):not(#vrtx-unitsearch):not(#vrtx-personsearch):not(#vrtx-personal-data):not(#vrtx-helseforsk):not(#vrtx-semester):not(#vrtx-program-frontpage):not(#vrtx-program-option):not(#vrtx-structured-project):not(#vrtx-person):not(#vrtx-course-description):not(#vrtx-student-exchange-agreement):not(#vrtx-organizational-unit):not(#vrtx-unit-person-listing):not(#vrtx-unit)
    #vrtx-main-user {
    > figure.image-left,
    > .vrtx-facts-container.vrtx-container-left:not(.info-fullwidth) {
      &:not(h1):not(.vrtx-introduction):not(#vrtx-main-user):not(blockquote) {
        margin-left: 0;
      }
    }
    .vrtx-introduction-image.small-intro-image,
    > figure.image-right,
    > .vrtx-facts-container.vrtx-container-right:not(.info-fullwidth) {
      &:not(h1):not(.vrtx-introduction):not(#vrtx-main-user):not(blockquote) {
        margin-right: 0;
      }
    }
  }

  // Reset js masonry feed
  .masonry-feed {
    height: auto !important;

    .vrtx-event-component-item,
    li {
      position: static !important;
    }
  }

  #footer-wrapper {
    background: transparent;

    *,
    a {
      color: #000;
    }
    #footers {
      background: url("@{imagePath}footer/uio-logo-no-black.svg") no-repeat 0 0;
      background-size: auto 96px;
      padding-top: 140px;
    }
  }

  body.en #footer-wrapper #footers {
    background-image: url("@{imagePath}footer/uio-logo-en-black.svg");
  }

  #footer-wrapper-back-to-uio {
    padding-top: 0;
    margin-top: -20px;
    background-color: #fff;
    a {
      background-image: url("@{imagePath}footer/uio-logo-no-black.svg");
    }
  }
  body.en #footer-wrapper-back-to-uio a {
    background-image: url("@{imagePath}footer/uio-logo-en-black.svg");
  }


  /* Media */

  div[id^='mediaspiller-'],
  object[id^='mediaspiller-'] {
    display: none !important;
  }

  /* Accordion */

  .ui-accordion {
    .ui-accordion-header {
      border-bottom: none !important;
      border-top: none !important;
      padding: 0 !important;
      margin: 60px 0 10px 0 !important;

      .ui-accordion-header-icon {
        display: none !important;
      }
    }
    .ui-accordion-content {
      padding: 0 !important;
      display: block !important;
    }
  }

  /* Page break rules */

  th, td, p, li, img, figure, #footer-wrapper {
    page-break-inside: avoid;
  }

  #footer-wrapper #footers .menu-label {
    font-size: 1.1rem;
  }

  #footer-wrapper *, #footer-wrapper a {
    font-size: 1.5rem;
    line-height: 1.9rem;
  }

  /* Typography */

  body {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  h1 {
    font-size: 3.6rem;
    line-height: 4.8rem;
    margin-bottom: 36px;
  }

  blockquote,
  blockquote.blockquote-large,
  #vrtx-frontpage #vrtx-frontpage-introduction, #vrtx-frontpage .vrtx-introduction, .vrtx-introduction, .vrtx-organizational-unit-introduction {
    font-size: 2.3rem;
    line-height: 3rem;

    cite {
      font-size: 1.9rem;
      line-height: 2.6rem;
    }
  }
  .vrtx-byline {
    font-size: 2rem;
    line-height: 2.8rem;
  }
  .vrtx-introduction+.vrtx-byline {
    margin-top: -10px;
  }

  #vrtx-frontpage #vrtx-frontpage-introduction, #vrtx-frontpage .vrtx-introduction, .vrtx-introduction, .vrtx-introduction-image, .vrtx-organizational-unit-introduction {
    margin-bottom: 36px;
  }

  .vrtx-introduction+.vrtx-introduction-image:not(.small-intro-image), .vrtx-introduction-image+.vrtx-introduction {
    margin-top: 0;
  }

  #vrtx-frontpage {
    .vrtx-frontpage-box {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }

    h1 {
      font-size: 3.6rem;
      line-height: 4.8rem;
    }

    h2 {
      font-size: 2.8rem;
      line-height: 3.6rem;
      letter-spacing: 0.2px;
    }

    .row > h2 ~ .vrtx-frontpage-box > h2,
    h3 {
      font-size: 2.3rem;
      line-height: 3.2rem;
      letter-spacing: 0.2px;
    }

    .vrtx-more a,
    .read-more,
    h4 {
      font-size: 1.9rem;
      line-height: 2.6rem;
      letter-spacing: 0.4px;
    }
    h4 {
      font-weight: normal;
    }

    h5 {
      font-size: 1.7rem;
      line-height: 2.7rem;
      font-weight: bold;
      letter-spacing: 0.2px;
    }
  }

  #main .vrtx-frontpage-box.img-special-left h2, #main .vrtx-frontpage-box.img-special-left h2 a.read-more, #main .vrtx-frontpage-box.img-special-right h2, #main .vrtx-frontpage-box.img-special-right h2 a.read-more {
    font-size: 2.8rem;
    line-height: 3.6rem;
    letter-spacing: 0.2px;
  }

}

// Consent

.consent-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 11;
}

.consent,
.consent-settings {
  background-color: #fff;
  position: fixed;
  width: 800px;
  max-width: 100%;
  top: ~"calc(50% - 200px)";
  left: ~"max(0.01px, calc(50% - 400px))";
  z-index: 12;

  &:focus {
    outline: none;
  }

  .consent-inner,
  .consent-settings-inner {
    position: relative;

    h2 {
      margin-top: 0;
      margin-bottom: 0;
    }
    p,
    .consent-main-intro-text,
    .consent-settings-main-buttons button,
    .consent-main-buttons button {
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 2.7rem;
    }
    .consent-settings-main-buttons,
    .consent-main-buttons {
      display: flex;
      align-items: flex-start;
      gap: 25px;
      padding: 18px 0px;

      button {
        width: auto;
        max-width: none;
        padding: 9px 29px 8px 29px;
        text-align: center;
        width: 200px;
      }
    }
  }
}

.consent {
  .consent-inner {
    p {
      margin: 0;
    }
  }
}

.consent h1,
#vrtx-frontpage .consent h1 {
  font-size: 2.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3.6rem;
  margin: 0;
}

.consent-settings-lang,
.consent-lang {
  color: var(--color-neutral-tint-20, rgba(0, 0, 0, 0.70));
  align-self: end;
}

.consent-settings-lang {
  margin-bottom: -60px;
}

// Consent A

.consent-a {
  .consent-inner {
    display: flex;
    padding: 60px 80px 80px 80px;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
    flex-shrink: 0;
  }
}

// Consent B

.consent-b {
  top: auto;
  bottom: 0;
  width: 100%;
  left: 0;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);

  .consent-inner {
    max-width: 1440px;
    min-width: 1050px;
    margin: 0 auto;
    padding: 40px 20px 60px 220px;
    display: flex;
    align-items: center;
    gap: 40px;

    .consent-main-buttons {
      padding: 0;
    }
    > div:first-child {
      display: flex;
      flex-direction: column;
      gap: 18px;
      max-width: 690px;
    }
    .consent-main-buttons,
    > div:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }
  }
  .consent-lang {
    display: none;
  }
}

// Big zoom on 1024x768@150% zoom UiO PCs
@media (min-width: 900px) and (max-height: 850px) {
  .consent-a {
    .consent-inner {
      display: flex;
      padding: 20px 40px 40px 40px;
      gap: 10px;
    }
  }
}

// Consent settings dialog

.consent-settings {
  position: fixed;
  width: 80%;
  height: 80vh;
  top: 10vh;
  left: 10%;
  max-width: none;
  overflow-y: auto;
  overflow-x: hidden;

  .consent-settings-intro {
    margin: 20px 0 0 0;
    max-width: 690px;
  }

  .consent-settings-inner {
    display: flex;
    padding: 40px 50px 20px 50px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;

    > div > h3:first-child {
      display: none;
    }

    .consent-settings-type {
      padding: 20px;
      background-color: var(--colorNeutralTint95, rgba(0, 0, 0, 0.05));
      width: 100%;

      h2 {
        margin-bottom: 20px;
      }
    
      h3.accordion {
        font-size: 1.8rem;
        line-height: 2.7rem;
        border-color: rgba(0, 0, 0, 0.20);
      }

      p {
        margin: 20px 0;
        max-width: 690px;
      }

      .ui-accordion.ui-widget {
        margin: 20px 0;
      }
    }

    .consent-settings-main-buttons {
      margin: -20px -50px;
      padding: 20px 50px;
      position: sticky;
      bottom: 0;
      left: 0;
      background-color: #fff;
      width: ~"calc(100% + 100px)";
      box-shadow: 0 0 10px 10px rgb(255 255 255);
    }
  }
}

body.consent-settings-opened {
  overflow: hidden;
}

.consent-settings-inner li {
  background-color: #fff;
  padding: 10px 20px;
  &:before {
    content: "";
  }
  a {
    .checkbox-style();
    &.checked {
      .checkbox-style-checked();
    }
    &.disabled {
      .checkbox-style-disabled();
    }
  }
  a.disabled {
    color: #666;
  }
  a:before {
    top: 4px;
  }
}

body#vrtx-frontpage .consent-settings,
.consent-settings {
  .consent-settings-inner {
    h1 {
      margin: 0;
      font-size: 4rem;
      line-height: 4.9rem;
    }
    .consent-settings-type {
      h2 {
        font-size: 2.8rem;
        line-height: 3.6rem;
      }
      h3.accordion {
        font-size: 1.8rem;
        line-height: 2.7rem;
        border-color: rgba(0, 0, 0, 0.20);
      }
    }
  }
}

// h1 override fix (and Ritmo special fix/override)
.consent h1,
#vrtx-frontpage .consent h1,
body#vrtx-frontpage .consent-settings h1,
.consent-settings h1 {
  opacity: 1;
  transform: none;
  font-family: var(--mainFontStack);
  color: var(--textColor);
  margin: 0;
  min-width: 0;
  height: auto;
  visibility: visible;
}

// Fix h1 that have been overriden on document body (and without #main specificity)
#vrtx-frontpage,
#vrtx-person,
#vrtx-course-description,
#vrtx-program-frontpage,
#vrtx-structured-article,
#vrtx-tagview {
  .consent,
  .consent-settings {
    h1 {
      margin: 0;
      padding: 0;
      display: block;
      max-width: none;
    }
  }
}

// borders

.consent-settings,
.consent-a {
  border: 1px solid #888;
}

@media @tabletConsentBreakpoint {
  .consent-settings,
  .consent-b {
    .consent-settings-inner,
    .consent-inner {
      max-width: 100%;
      min-width: auto;
    }
  }
  .consent-settings-lang {
    margin-bottom: -20px;
  }
  .consent-b {
    .consent-inner {
      padding-left: 25px;
    }
  }
}

@media @responsiveConsentBreakpoint {
  body.consent-settings-opened-mobile {
    overflow: hidden;

    .consent {
      overflow-y: auto;
      overflow-x: hidden;
      height: ~"calc(100vh - 65px)";
      top: 65px;
    }
  }

  .consent-settings  {
    .consent-settings-inner {
      padding: 30px 15px 20px 15px;

      .consent-settings-main-buttons {
        gap: 15px;
      }
    }
    width: 100%;
    height: ~"calc(100vh - 65px)";
    top: 65px;
    left: 0;
  }

  #vrtx-frontpage .consent h1, .consent h1 {
    font-size: 1.9rem;
    font-weight: 700;
    line-height: 2.6rem;
  }
  .consent-settings .consent-settings-inner h1,
  body#vrtx-frontpage .consent-settings .consent-settings-inner h1,
  .consent-settings .consent-settings-inner .consent-settings-type h2,
  body#vrtx-frontpage .consent-settings .consent-settings-inner .consent-settings-type h2  {
    font-size: 1.9rem;
    font-weight: 700;
    line-height: 2.6rem;
  }

  .consent-settings .consent-settings-inner .consent-settings-main-buttons,
  .consent .consent-inner .consent-main-buttons {
    flex-direction: column;
  }
  .consent-settings-lang {
    margin-bottom: -66px;
    position: relative;
    z-index: 2;
  }
  
  #vrtx-frontpage,
  #vrtx-person,
  #vrtx-course-description,
  #vrtx-program-frontpage,
  #vrtx-structured-article,
  #vrtx-tagview {
    .consent,
    .consent-settings {
      h1 {
        padding-right: 100px;
      }
    }
  }
  .consent-title,
  .consent-settings-title {
    padding-right: 100px;
  }

  .consent {
    .consent-inner {
      padding: 30px 15px 100px 15px;
      align-items: start;
       
      .consent-main-buttons {
        gap: 15px;
        padding: 0;

        &,
        button {
          width: 100%;
        }
      }
    }
  }

  body .consent,
  body .consent-settings {
    .consent-inner,
    .consent-settings-inner {
      a,
      p,
      .consent-settings-intro,
      .consent-main-intro-text,
      .consent-settings-main-buttons button,
      .consent-main-buttons button {
        font-size: 1.6rem;
        line-height: 2.2rem;
      }
    }
  }

  .consent .consent-inner {
    flex-direction: column;
    gap: 15px;

    >div:last-child {
      width: 100%;
    }

    .consent-lang {
      display: inline;
      margin-bottom: -35px;
      position: relative;
      z-index: 1;
    }
  }
}
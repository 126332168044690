// Global menu and menu button

@uioRed: #b60000; // www.uio.no only opened menu background

.sidebar-menu-toggle {
  color: @textColor;
  text-decoration: none;
  padding: 0;
  margin: 0;
  border: none;
  font-size: 1.6rem;
  letter-spacing: inherit;

  span {
    display: inline-block;
    position: relative;
    top: -4px;
  }

  &:after {
    content: "";
    background: url("@{imagePath}sidebar/sidebar-open.svg") no-repeat center
      left;
    background-size: auto 100%;
    height: 22px;
    width: 40px;
    display: inline-block;
    margin-left: 12px;
  }

  &:focus {
    outline: 3px solid #0f4bf3;
  }
  &.click-focus {
    outline: none;
  }

  &:focus,
  &:hover {
    background: transparent;
  }
}

body.sidebar-menu-wrapper-visible .sidebar-menu-toggle {
  span {
    top: -7px;
  }
  &:after {
    background-image: url("@{imagePath}sidebar/sidebar-close.svg");
    height: 25px;
    width: 25px;
  }
}

// Seal

.sidebar-seal {
  position: absolute;
  top: 517px;
  left: 25px;
  display: block;
  background: url("@{imagePath}sidebar/seal.svg") no-repeat 0 0;
  background-size: 100% auto;
  width: 138px;
  height: 138px;
  display: block;
}

// Menu
.sidebar-menu {
  margin-bottom: 60px;
}

body:not(.sidebar-menu-wrapper-visible) .sidebar-menu-wrapper {
  visibility: hidden;
  height: 0;
}

.sidebar-menu-wrapper {
  width: 100%;
  background: #fff;
  padding: 0;
  position: relative;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
  will-change: height;

  .sidebar-menu >*:not(.uio) {
    max-width: @gridWidth;
    min-width: @gridMinWidth;
    padding-right: @contentGapRight;
    padding-left: @sidebarWidth;
    margin-left: ~"max(0.01px, calc(calc(calc(var(--width) - @{gridWidth}) / 2)))";
  }
  .sidebar-menu-inner-wrapper {
    position: relative;

    &.uio {
      &:after {
        display: none;
      }
      > * {
        max-width: @gridWidth;
        min-width: @gridMinWidth;
        padding-right: @contentGapRight;
        padding-left: @sidebarWidth;
        margin-left: ~"max(0.01px, calc(calc(calc(var(--width) - @{gridWidth}) / 2)))";
      }
    }
  }

  a {
    color: @textColor;
    display: block;

    .textUnderlineSwitch;
  }

  form.vrtx-search-form,
  form.vrtx-big-search,
  .search-form,
  form#enheter,
  form#emner,
  form#personer {
    input[type="text"],
    input[type="search"] {
      padding: 8px 38px 7px 0;
      letter-spacing: 0.33px;
      background: transparent;
      border: 1px solid @borderColor;
      border-width: 0 0 1px 0;

      &:focus,
      &:hover {
        border-width: 0 0 1px 0;
      }
      &:focus {
        outline: none;
      }
    }
    button[type="submit"],
    input[type="submit"] {
      opacity: 1;
      width: 22px;
      height: 22px;
      top: 13px;
      right: 0;

      &:focus {
        outline: 1px solid #888;
      }
    }
  }
}
.sidebar-menu-wrapper-visible {
  .sidebar-menu-wrapper {
    height: var(--heightVisible);
  }
}
.sidebar-global-menu + .sidebar-services-language-menu {
  display: none;
}

// Menu components

.sidebar-left-menu,
.sidebar-services-language-menu {
  a + ul {
    margin-top: 10px;
  }
  li {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }

    a {
      .baseFontSize;
    }
  }
}


.sidebar-services-language-menu:first-child {
  li {
    margin-left: 0;
    &:before {
      display: none;
    }
  }
  li a {
    font-size: 1.5rem;

    .textUnderlineSwitch;
  }
}

body.www\.uio\.no:not(.header-context) .sidebar-services-language-menu:first-child {
  li a {
    color: #fff;
  }
}

.sidebar-search.search-form {
  margin-top: 0;
  margin-bottom: 50px;
  width: 100%;
  max-width: 1000px;
  &:after {
    .clearfix();
  }
  &:before {
    .clearfix();
  }

  input[type="text"] {
    max-width: 100%;
  }

  input[type="text"],
  button {
    font-size: 2.1rem;
    font-family: @mainFontStack;
  }
}

.sidebar-global-menu {
  font-size: 2.1rem;
  line-height: 2.9rem;
  padding-bottom: 80px;

  .vrtx-tab-menu {
    margin-bottom: 0;
  }

  li {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.sidebar-services-language-menu:first-child {
  position: absolute;
  left: 25px;
  top: 99px;
}

// UiO
.sidebar-menu-wrapper .uio.sidebar-menu-inner-wrapper {
  background: @uioRed;
  margin: 0;
  padding: 30px 0;
  width: 100%;
  //width: ~"calc(100% + 225px)";

  input[type="text"] {
    .placeholderColor (#fff, #fff);
    border-color: #fff;
    border-width: 0 0 1px 0;

    &:focus,
    &:hover {
      border-width: 0 0 1px 0;
    }
  }

  form.vrtx-search-form,
  form.vrtx-big-search,
  .search-form,
  form#enheter,
  form#emner,
  form#personer {
    button[type="submit"],
    input[type="submit"] {
      background-image: url("@{imagePath}sidebar/magnifying-glass-white.svg");
    }
  }

  .sidebar-services-language-menu {
    border-color: #fff;
  }

  *,
  a {
    color: #fff;
  }
}

body.www\.uio\.no:not(.header-context) {
  #head-wrapper {
    //transition: background .6s ease-in-out;
  }
  &.sidebar-menu-wrapper-visible {
    #head-wrapper {
      background: @uioRed;
    }
    #header-language span {
      border-color: #fff;
    }
    #head-wrapper {
      *,
      a {
        color: #fff;
      }
    }
    .sidebar-menu {
      background: @uioRed;
    }
    .sidebar-menu-toggle {
      &:hover,
      &:focus {
        span {
          //.textUnderlineHover(#fff);
          .textUnderline(currentColor);
        }
      }
      &:after {
        background-image: url("@{imagePath}sidebar/sidebar-close-white.svg");
      }
    }
    .uio-host {
      background-image: url("@{imagePath}header/uio-logo-white-no.svg");
    }
    &.en .uio-host {
      background-image: url("@{imagePath}header/uio-logo-white-en.svg");
    }
  }
  .sidebar-menu-wrapper {
    input[type="text"] {
      .placeholderColor (#fff, #fff);
      border-color: #fff;
    }
    *,
    a {
      color: #fff;
    }
  }
  .sidebar-menu {
    form.vrtx-search-form,
    form.vrtx-big-search,
    .search-form,
    form#enheter,
    form#emner,
    form#personer {
      button[type="submit"],
      input[type="submit"] {
        background-image: url("@{imagePath}sidebar/magnifying-glass-white.svg");
      }
    }
    .sidebar-menu-inner-wrapper {
      padding-bottom: 0;
    }
    .sidebar-search {
      margin-top: 0;
    }
    .sidebar-left-menu,
    .sidebar-services-language-menu {
      border-color: #fff;
    }
    .sidebar-left-menu .vrtx-ancestor + .vrtx-ancestor {
      border-color: #fff;
    }
    .sidebar-left-menu .vrtx-child > a.vrtx-marked,
    .sidebar-left-menu .vrtx-parent > a.vrtx-marked {
      background: #fff;
  
      &,
      * {
        color: @textColor;
      }
    }
  }
}

@media @desktopSmallBreakpoint {
  #main #left-main {
    position: absolute;
    right: 15px;
    top: 30px;
    left: auto !important;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    display: block;
  }
  .sidebar-menu {
    margin-left: -25px;
    margin-right: -20px;
    padding-left: 25px;
    padding-right: 20px;
  }
  .sidebar-search {
    margin-top: 0;
  }
  .sidebar-services-language-menu:first-child,
  #left-main .uio-acronym {
    display: none;
  }

  .sidebar-global-menu + .sidebar-services-language-menu {
    display: flex;
    border-top: 1px solid @borderColor;
    flex-direction: row;
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;

    li {
      flex: 1;
      display: block;
      margin-right: 40px;

      &.language {
        display: none;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }

  // Menu

  body.www\.uio\.no:not(.header-context) {
    &.sidebar-menu-wrapper-visible {
      /*
      .uio-acronym {
        display: block;
        background-image: url("@{imagePath}header/uio-logo-acronym-white.svg");
      }
      .uio-host {
        display: none;
      }
      .sidebar-search {
        margin-top: 0;
      }
      */
    }
  }

  .sidebar-menu-wrapper .sidebar-menu-inner-wrapper.uio > *,
  .sidebar-menu-wrapper .sidebar-menu >*:not(.uio) {
    max-width: none;
    min-width: 0;
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .sidebar-menu-wrapper
    .uio.sidebar-menu-inner-wrapper {
    padding: 20px 0;
    margin-left: -25px;
    margin-top: 20px;
    width: ~"calc(100% + 25px + 20px)";
  }
}

@media @desktopSmallOnlyBreakpoint {
  .sidebar-menu-wrapper,
    .sidebar-menu-wrapper
    .uio.sidebar-menu-inner-wrapper {
    padding-left: 25px;
    padding-right: 20px;
  }
}

@media @responsiveBreakpoint {
  body:not(.www\.uio\.no) {
    &.sidebar-menu-wrapper-visible {
      // Context
      .uio-app-subtitle .uio-host {
        font-size: 1.7rem;
      }
    }
  }

  .sidebar-menu {
    margin-bottom: 40px;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .sidebar-menu-wrapper,
  .sidebar-menu-wrapper
    .uio.sidebar-menu-inner-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
  .sidebar-menu-wrapper
    .uio.sidebar-menu-inner-wrapper {
    margin-left: -15px;
    width: ~"calc(100% + 15px + 15px)";
  }

  body.sidebar-menu-wrapper-visible {
    #main #left-main {
      margin-top: 0 !important;
    }
  }

  .sidebar-menu-toggle span {
    display: none;
    &:after {
      margin-left: 0;
      margin-right: 15px;
    }
  }
}

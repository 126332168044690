.vrtx-list-articles,
.vrtx-subfolder-menu {
  .menu-title {
    font-size: 2.8rem;
    line-height: 3.6rem;
    letter-spacing: .2px;
    margin-top: 60px;
    margin-bottom: 10px;
  }
}

// Set this in prod when the updated subfolder-menu has been deployed
// .vrtx-subfolder-menu {
//   &[class*='vrtx-subfolder-menu-sets-'] > ul {
//     column-count: 3;
//     overflow-wrap: anywhere;
//     -webkit-hyphens: auto;
//     hyphens: auto;
//   }
//   &.vrtx-subfolder-menu-sets-1 > ul {
//     column-count: 1;
//   }
//   &.vrtx-subfolder-menu-sets-2 > ul {
//     column-count: 2;
//   }
//   &.vrtx-subfolder-menu-sets-4 > ul {
//     column-count: 4;
//   }
// }

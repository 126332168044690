#vrtx-structured-project-two {
  .vrtx-fullsize-image-title {
    position: relative;

    h1 {
      max-width: 750px;
    }
  }

  //
  .vrtx-fullsize-image {
    margin-left: -195px;
    max-width: 1400px;

    img {
      display: block;
    }
  }

  #main h1 {
    position: absolute;
    bottom: 36px; // line-height on .vrtx-title adds 4px extra
    margin: 0;
    line-height: 0; // remove extra space above short-title

    span {
      outline: 6px solid #FFF;
      background-color: #fff;
      position: relative;
      left: 6px;
    }

    .vrtx-short-title {
      font-size: 4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      outline-color: #000;
      background-color: #000;
      color: #fff;
      display: table;
      margin-bottom: 1rem;
    }
  
    .vrtx-title {
      font-size: 5rem;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      display: inline;
    }
  }

  .vrtx-article-body {
    max-width: 690px;
  }

  // If h1 is not inside wrapper (image is not big variant)
  #vrtx-main-content > h1 {
    position: static;
    margin-bottom: 40px;

    span {
      position: static;
      outline: none;
    }

    .vrtx-short-title {
      display: inline-block;
      padding: 6px;
    }
    
    .vrtx-title {
      outline: none;
      display: block;
    }
  }

  .project-status-bar {
    margin-top: 40px;
    margin-bottom: 40px;

    display: inline-block;
    padding: 5px 20px;
    border: 1px solid var(--colorNeutralTint80);
    background: #FFF;

    font-size: 1.8rem;
    font-style: normal;
    line-height: 2.7rem;

    dt,
    dd {
      display: inline-block;
      margin: 0;
    }

    dt {
      font-weight: 700;
      color: var(--colorNeutralTint20);
    }
    dd {
      margin-right: 40px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  h1 + .project-status-bar { // margins doesnt cancels eachother out (maybe cause short title inside h1 isn't block)
    margin-top: 0px;
  }

  .vrtx-introduction + .vrtx-middle-image {
    margin-top: -25px;
  }
}

@media @desktopSmallBreakpoint {
  #vrtx-structured-project-two {
    .vrtx-fullsize-image {
      margin-left: 0;
    }

    #main h1 {
      position: static;
      margin-bottom: 40px;

      span {
        position: static;
      }

      .vrtx-short-title {
        font-size: 1.8rem;
        line-height: 125%;
        display: inline-block;
        padding: 6px;
        outline: none;
      }
    
      .vrtx-title {
        font-size: 3.8rem;
        line-height: 4.8rem;
        outline: none;
        display: block;
      }
    }
  }
}
@media @responsiveBreakpoint {
  #vrtx-structured-project-two {
    .project-status-bar {
      display: inline-grid;
      grid-template-columns: auto auto;
      gap: 0 10px;
    }
  }
}
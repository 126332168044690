#vrtx-program-frontpage h1 {
  margin-bottom: 80px;
}

body {
  &#vrtx-program-frontpage,
  &#vrtx-program-option {
    h2 a {
      text-decoration-color: transparent;
      &:hover,
      &:focus {
        .textUnderline(@textColor);
      }
    }
    .vrtx-introduction {
      margin-bottom: 60px;
      margin-top: 8px;
    }
    #main.main ul.navigation-links {
      margin-bottom: 0;
      row-gap: 30px;
      li { 
        margin-bottom: 0;
      }
    }
    #vrtx-program-links ul {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      li {
        margin-bottom: 0;
      }
    }
  }
}

#vrtx-program-frontpage,
#vrtx-program-option {
  .vrtx-introduction {
    margin-bottom: 60px;
  }
  .vrtx-frontpage-box {
    position: relative;

    .vrtx-box-content {
      .firstLastChildNoMargin();
    }
  }
  .vrtx-program-resource-links {
    .left,
    .right {
      .firstLastChildNoMargin();
    }
  }
  .vrtx-introduction-image {
    display: none;
  }
}

#vrtx-program-frontpage-main,
#vrtx-program-option-main {
  margin-bottom: 0;
}

#vrtx-program-frontpage,
#vrtx-program-option {
  .vrtx-introduction-image {
    margin-bottom: 80px;
    float: none;
    width: auto !important;
    max-width: @h1IntroWidth !important;
    img {
      width: 100% !important;
    }
  }
  .vrtx-introduction-image {
    margin-left: 0;
  }
}

#vrtx-program-links {
  width: @h1IntroWidth;
  max-width: 100%;
  margin-bottom: 80px;
  padding: 50px;
  background: @green;
  &:after {
    .clearfix();
  }
}

#vrtx-program-links,
#vrtx-program .vrtx-program-options {
  ul {
    &,
    &:last-child {
      margin-bottom: 0;
    }
  }
}

#vrtx-program-option .vrtx-program-title-box {
  h3 {
    .baseFontSize;
    position: relative;
  }
}

#main #vrtx-main-content .vrtx-program-contact-box {
  .vrtx-more {
    position: static;
    margin-top: 15px;
  }
}

#vrtx-program-frontpage .vrtx-frontpage-box {
  ul.only-links a {
    text-decoration: none;
    &:hover,
    &:focus {
      .textUnderline(@textColor);
    }
  }
}

#vrtx-program-facts {
  max-width: @h1IntroWidth;
  width: auto;
  margin-top: 0;
  margin-bottom: 15px;
  padding: 8px 14px 7px 14px;
  border: solid 1px @borderColor;
  display: inline-block;
  font-size: 1.6rem;
  line-height: 2.9rem;
  letter-spacing: 0.6px;
  display: inline-block;
  th,
  td,
  tr {
    padding: 0;
    border: none;
    display: inline-block;
  }
  caption {
    display: none;
  }
  th {
    font-weight: bold;
    width: auto; // override table.vertical
  }
  td {
    margin-left: .25em;
    margin-right: 15px;

    &,
    strong {
      font-weight: normal;
    }
  }
}

body#vrtx-program-frontpage #vrtx-program-facts {
  max-width: @h1IntroWidth;
  margin-bottom: 15px;
  padding: 8px 14px 7px 14px;
  border: solid 1px @borderColor;
  display: inline-block;
  // Clear inline block
  clear: both;
  h2 {
    display: none;
  }

  *,
  h3 {
    font-size: 1.6rem;
    line-height: 2.9rem;
    letter-spacing: 0.6px;
    display: inline-block;
    margin: 0;
  }

  h3,
  dt {
    font-weight: bold;
  }
  p,
  dd {
    margin-left: .25em;
    margin-right: 15px;
    &,
    strong {
      font-weight: normal;
    }
  }
}

// Program option
#vrtx-program-frontpage,
#vrtx-program-option-main {
  .vrtx-introduction-image {
    width: auto !important;
    float: none;
    margin-left: 0;
  }
}

.vrtx-program-title-box {
  h3 {
    margin-top: 0;
  }
}

.vrtx-program-resource-links {
  width: var(--width);
  margin-left: ~"calc(calc(calc(calc(var(--width) - calc(100%)) / 2) + 100px) * -1)";
  background: @boxGreyBg;
  padding-top: 80px;
  padding-bottom: 80px;

  .vrtx-program-resource-links-inner {
    max-width: @gridWidth;
    min-width: @gridMinWidth;
    padding-right: @contentGapRight;
    padding-left: @sidebarWidth;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    &:after {
      .clearfix();
    }
  }
}

body {
  #main .vrtx-program-options li a:not(.button):not(.button-large) {
    padding-left: 20px;
    display: block;
    &:before {
      display: none;
    }
  }
}

@media @desktopSmallOnlyBreakpoint {
  .vrtx-program-resource-links {
    margin-left: -25px;

    .vrtx-program-resource-links-inner {
      min-width: @responsiveBreakpointPx;
      padding-left: 25px;
    }
  }
}

@media @responsiveBreakpoint {

  // Make fullwidth in responsive
  #vrtx-program-links {
    padding: 30px 15px;
    margin-left: -15px;
    margin-right: -15px;
    width: ~"calc(100% + 30px)";
    max-width: none;
  }
  #vrtx-program-facts {
    padding: 5px 10px;
    font-size: 1.3rem;
    line-height: 2.3rem;
    letter-spacing: 0.22px;
  }
  .vrtx-program-resource-links {
    width: auto;
    padding: 30px 0;
    margin-left: -15px;
    margin-right: -15px;
    width: ~"calc(100% + 30px)" !important;

    .vrtx-program-resource-links-inner {
      max-width: none;
      min-width: 0;
      padding-right: 15px;
      padding-left: 15px;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

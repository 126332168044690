// Hidden
.search-string-label {
  position: absolute;
  left: -9999px;
  top: -1000px;
}

input[type="password"],
input[type="number"],
input[type="text"],
input[type="email"],
input[type="search"],
input[type="tel"],
input[type="url"] {
  font-size: 2.2rem;
  line-height: 3.1rem;
  font-family: @mainFontStack;
  letter-spacing: 0.38px;
  max-width: ~"calc(100% - 45px)";
  width: 100%;
  padding: 19px 21px 18px 21px;
  margin: 0;
  background: #fff;
  border: 1px solid #828890;
  .transition(border, .2s, ease-in-out);

  outline: none;
  background-image: none; /* remove inner shadow in Firefox on Android */
  -webkit-appearance: none; /* remove inner shadow in Safari on iOS */
  -webkit-border-radius: 0;
  appearance: none;
  border-radius: 0;

  &:focus,
  &:hover {
    border: 1px solid #0e0a36;
  }

   /* Remove red border in Firefox */
  &:invalid {
    .boxShadow(none);
  }
  .placeholderColor (#222, #888);
}

.vrtx-button:not(form),
.vrtx-button-larger:not(form),
.button,
.button-large,
.button-large-blue,
input[type="button"],
input[type="submit"],
input[type="reset"],
.ui-dialog.ui-widget button,
button {
  font-size: 2.2rem;
  line-height: 3.1rem;
  font-family: @mainFontStack;
  letter-spacing: 0.38px;
  max-width: 450px;
  width: 100%;
  padding: 20px 20px 18px 20px;
  margin: 0;
  background: transparent;
  color: @btnSecondaryColor;
  border: none;
  text-align: left;

  cursor: pointer;
  text-decoration: none;

  outline: none;
  display: inline-block;
  -webkit-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;

  position: relative;

  &::-moz-focus-inner { /* remove extra padding in Firefox */
    padding: 0;
    border: none;
  }

  &:focus {
    outline: 3px solid #0f4bf3;
  }
  &.click-focus {
    outline: none;
  }

  &:focus,
  &:hover {
    background: @btnSecondaryBgHover;
    color: @btnSecondaryColorHover;
    text-decoration: none;
  }
}

// Secondary button
.vrtx-button:not(form),
.button,
input[type="button"],
input[type="submit"],
input[type="reset"],
.ui-dialog.ui-widget button,
button {
  padding: 19px 19px 17px 19px;
  border: 1px solid #18191c;
}

// Primary button
.button-large,
.button-large-blue,
.vrtx-button-larger:not(form) {
  background: @btnPrimaryBg;
  color: @btnPrimaryColor;
  border: none;

  &:focus,
  &:hover {
    background: @btnPrimaryBgHover;
    color: @btnPrimaryColorHover;
  }
}

textarea {
  max-width: 100%;
}

.buttonArrowStyle() {
  padding-right: 60px;
  .transition(padding, .2s, ease-in-out);

  &:hover,
  &:focus {
    padding-left: 30px;
  }

  &:after {
    content: "";
    background: url("@{imagePath}arrows/arrow-big.svg") no-repeat 0 0;
    background-size: 100% auto;
    height: 1.1em;
    width: 1.1em;
    position: absolute;
    top: 27px;
    right: 20px;
  }
}

.buttonArrowStyleWhiteArrow() {
  &:after {
    background-image: url("@{imagePath}arrows/arrow-big-white.svg");
  }
}

// Arrow when button is in a frontpage box (or other explicitely specified)
.vrtx-frontpage-box,
.leganto-url {
  .vrtx-button:not(form),
  .vrtx-button-larger:not(form),
  .button:not(.feedback-yes):not(.feedback-no),
  .button-large,
  .button-large-blue,
  input[type="button"],
  input[type="submit"],
  input[type="reset"],
  .ui-dialog.ui-widget button,
  button {
    .buttonArrowStyle();
  }

  .button-large,
  .button-large-blue,
  .vrtx-button-larger:not(form) {
    .buttonArrowStyleWhiteArrow()
  }
}

// When in use in other doc types (avoid targeting <form>)
a.button:not(.feedback-yes):not(.feedback-no),
a.button-large,
a.button-large-blue {
  .buttonArrowStyle();
}
a.button-large,
a.button-large-blue {
  .buttonArrowStyleWhiteArrow();
}

// Search boxes
#error .searchform,
form.vrtx-search-form,
form.vrtx-big-search,
.search-form,
form#enheter,
form#emner,
form#personer,
form#search-articles,
form#search-programs-abroad {
  position: relative;
  max-width: ~"calc(100% - 45px)";
  min-width: 270px;
  width: 100%;

  label {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
  input[type="text"],
  input[type="search"] {
    padding-right: 63px;
    max-width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-bottom: 2px solid #18191c;

    &:focus {
      outline: 3px solid #0f4bf3;
    }
    &.click-focus {
      outline: none;
    }

    &:focus,
    &:hover {
      border-color: #18191c;

      ~ button[type="submit"],
      ~ input[type="submit"] {
        opacity: 1;
      }
    }
  }
  input[type="text"],
  input[type="search"],
  button[type="submit"],
  input[type="submit"] {
    vertical-align: top;
    margin-top: 0;
    margin-right: 0;
    width: 100%;
  }
  button[type="submit"],
  input[type="submit"] {
    position: absolute;
    top: 24px;
    right: 21px;
    overflow: visible;
    border: none;
    overflow: hidden;
    text-indent: -9999px;
    width: 25px;
    height: 26px;
    padding: 0;
    background: url('@{imagePath}sidebar/magnifying-glass.svg') no-repeat 0 0;
    background-size: 100% auto;
    opacity: 1;
    .transition(opacity, .2s, ease-in-out);

    &:after {
      display: none;
    }

    &:focus {
      outline: 3px solid #0f4bf3;
    }
    &.click-focus {
      outline: none;
    }
  }
}

.checkbox-style() {
  color: @textColor;
  margin-left: 30px;
  display: inline-block;
  position: relative;

  &:before {
    content: " ";
    height: 18px;
    width: 18px;
    background: transparent url('@{imagePath}form-elements/unchecked.svg') no-repeat center center;
    background-size: 18px auto;
    margin-left: -30px;
    position: absolute;
    top: 6px;
    left: 0;
  }
  .textUnderlineSwitch;
}

.checkbox-style-checked() {
  &:before {
    background-image: url('@{imagePath}form-elements/checked.svg');
  }
}
.checkbox-style-disabled() {
  color: #aaa;

  &:hover {
    cursor: default;
  }
  &:hover,
  &:focus {
    text-decoration: none;
  }
  &:before {
    opacity: 0.4;
  }
}

@media @desktopSmallBreakpoint {
  #error .searchform, .search-form, form#emner, form#enheter, form#personer, form#search-articles, form#search-programs-abroad, form.vrtx-big-search, form.vrtx-search-form {
    min-width: 100%;
  }
}

@media @responsiveBreakpoint {
  input[type="password"],
  input[type="number"],
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="tel"],
  input[type="url"] {
    max-width: 100%;
    font-size: 2rem;
  }
  #error .searchform,
  form.vrtx-search-form,
  form.vrtx-big-search,
  .search-form,
  form#enheter,
  form#emner,
  form#personer, 
  form#search-articles,
  form#search-programs-abroad {
    max-width: 100%;
  }

  /* Special cases */
  #search-articles-string, #search-programs-abroad-string {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
